<template>
<!--  {{ category }}-->
  <div class="image">
    <img
        class="img"
        :src="image"
        v-if="showImage"
        :style="imageHeight"
    />
  </div>

  <confirm-modal
      @success="openPage"
      :title="$t('frames.title')"
      v-if="showPageDialog"
      @close="showPageDialog = false"
      :successButtonTitle="$t('frames.open')">
    {{ $t('frames.message') }}
  </confirm-modal>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: "NewFrame",
  inheritAttrs: false,
  props: [
    'layout',
    'category',
    'isDigitalSignage',
  ],
  computed: {
    ...mapGetters({
      restURI: 'config/restURI'
    }),
    image() {
      if (this.category) {
        const media = this.category?.media?.links?.find(l => l.rel === 'preview')
        if (media) {
          return media.href
        }
      }
      return this.category?.media?.links[0]?.href
    },
    showImage() {
      return this.image
          && (this.layout !== 'homepage'
              || (this.layout === 'homepage' && !this.$helper.findAttribute(this.category, 'Media')?.inherited))
    }
  },
  data() {
    return {
      showPageDialog: false,
      imageHeight: {
        //height: '100%'
      }
    }
  },
  // async created() {
    // if (window.length > 1)
    //   window.history.back()
    //// if (templateAttr?.value?.toLowerCase() !== 'splitview') {
    //// window.location = window.history.state.back
  // },
   mounted() {
     setTimeout(() => {
       let z = window.open(this.category.frameUrl, "support", "_blank")
       if (!z) {
         this.showPageDialog = true
       } else {
         window.history.back()
       }
     }, 500);
  },
  methods: {
    async openPage() {
      window.open(this.category.frameUrl, "support", "_blank")
    }
  }
}


</script>

<style lang="scss" scoped>
.image {
  @apply
  flex
  w-full
  h-full
  items-center
  justify-center
  max-w-full
  max-h-full;
}

.img {
  @apply
  mx-auto
  max-w-full
  max-h-full;
}
</style>